window.$ = require("jquery");
window.jQuery = window.$;

function setMinHeight() {
    var minHeight = Infinity;
    var maxHeight = 0;

    $(".collapsible-boxes .article-card__inner").each(function () {
        var thisHeight = $(this).innerHeight();
        if (thisHeight > maxHeight) {
            maxHeight = thisHeight;
        }
    });

    $(".collapsible-boxes .article-card__inner").each(function () {
        var thisHeight = $(this).innerHeight();

        if ($(this).children(".article-card__content.limited-text").length > 0) {
            if (thisHeight < minHeight) {
                minHeight = thisHeight;
            }
            var newHeight = maxHeight;
            if ($(this).closest(".grid-item").find(".article-card__continue").length > 0) {
                newHeight += 30;
            }
            $(this).closest(".grid-item").css("height", newHeight);
            $(this).closest(".grid-item").data("original-height", newHeight);

            $(".collapsible-boxes .article-card__content:not(.open)").not(".limited-text").closest(".grid-item").css("height", newHeight);
            $(".collapsible-boxes .article-card__content:not(.open)").not(".limited-text").closest(".grid-item").data("original-height", newHeight);
        } else if ($(this).children(".article-card__content.open").length > 0) {
            var innerHeight = $(this).innerHeight();
            $(this).closest(".grid-item").css("height", innerHeight);
            $(this).closest(".grid-item").data("original-height", innerHeight);
        } else {
            var newHeight = maxHeight;
            if ($(this).closest(".grid-item").find(".article-card__continue").length > 0) {
                newHeight += 30;
            }
            $(this).closest(".grid-item").css("height", newHeight);
        }
    });
}

$(function () {
    $(window).resize(function () {
        setMinHeight();
    });

    $(".collapsible-boxes .article-card__content").each(function () {
        var lineHeight = parseInt($(this).css("line-height"), 10);
        var height = $(this).height();
        var lines = height / lineHeight;
        if (lines > 11) {
            $(this).addClass("limited-text");
        } else {
            $(this)
                .closest(".grid-item")
                .find(".article-card__continue")
                .css("display", "none");
        }
    });

    var baseUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        "/wp-content/themes/cupole";
    var moreImgSrc = baseUrl + "/img/icon-plus-circle-white.svg";
    var lessImgSrc = baseUrl + "/img/icon-minus-circle-white.svg";

    $(".collapsible-boxes .continue-img").attr("src", moreImgSrc);

    $(".collapsible-boxes .article-card__continue").click(function () {
        var gridItem = $(this).closest(".grid-item");
        var content = $(this)
            .closest(".grid-item")
            .find(".article-card__content");
        var moreText = "Read more";
        var lessText = "Read less";

        content.toggleClass("limited-text");

        $(".collapsible-boxes .grid-item").css(
            "transition",
            "height 0.25s ease"
        );

        if (content.hasClass("limited-text")) {
            $(this).find("span").text(moreText);
            $(this).find(".continue-img").attr("src", moreImgSrc);
            var newHeight = gridItem.data("original-height");
            gridItem.css("height", newHeight);
        } else {
            content.addClass("open");
            $(this).find("span").text(lessText);
            $(this).find(".continue-img").attr("src", lessImgSrc);
            var innerHeight = gridItem
                .find(".article-card__inner")
                .innerHeight();
            if (innerHeight > gridItem.data("original-height")) {
                gridItem.css("height", innerHeight + 30);
            }
        }
    });
});

$(document).ready(function () {
    setMinHeight();
});